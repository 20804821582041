import React from 'react';
import ReactDOM from 'react-dom';
import { UserContextProvider } from './components/UserContextManager';
import { AccountingContextProvider } from './components/AccountingContextManager';
import { NUContextProvider } from './components/NUHelpersManager';
import { ManagementContextProvider } from './components/ManagementHelpersManager';
import { DesignContextProvider } from './components/DesignContextManager';
import { OPContextProvider } from './components/OPContextManager';
import { CCContextProvider } from './components/CCContextManager';
import { MarketingContextProvider } from './components/MarketingContextManager';
import { NsdoContextProvider } from './components/NsdoContextManager';
import { DKContextProvider } from './components/DKContextManager';
import { FundContextProvider } from './components/FundContextManager';
import { MechContextProvider } from './components/MechContextManager';
import { NPContextProvider } from './components/NPContextManager';
import { SMPContextProvider } from './components/SMPContextManager';
import { NuSpbContextProvider } from './components/NuSpbContextManager';
import { ManagementSpbContextProvider } from './components/ManagementSpbContextManager';

import './index.css';
import App from './App';
import { ManagementSecretContextProvider } from './components/ManagementSecretContext';
import { DesContextProvider } from './components/DesContextManager';
import { SupplySpbContextProvider } from './components/SupplySpbContextManager';
import { SupplyContextProvider } from './components/SupplyContextManager';

window.redesk_app = {
  accounting: {
      in_excerpts: {},
      loaded_excerpts: {}
  },
  user_settings: {
    per_page: 100,
  },

  common: {
    fmtFinValue: new Intl.NumberFormat('ru-RU', {'minimumFractionDigits': 2}),
    fmtMoneyValue: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' })
  }
};

ReactDOM.render(
  <React.StrictMode>
    <SupplyContextProvider>
    <SupplySpbContextProvider>
    <DesContextProvider>
    <ManagementSecretContextProvider>
    <ManagementSpbContextProvider>
    <NuSpbContextProvider>
    <SMPContextProvider>
    <NPContextProvider>
    <FundContextProvider>
    <MechContextProvider>
    <DKContextProvider>
    <NsdoContextProvider>
    <MarketingContextProvider>
    <CCContextProvider>
    <OPContextProvider>
    <DesignContextProvider>
    <ManagementContextProvider>
    <NUContextProvider>
    <AccountingContextProvider>
    <UserContextProvider>
        <App />
    </UserContextProvider>
    </AccountingContextProvider>
    </NUContextProvider>
    </ManagementContextProvider>
    </DesignContextProvider>
    </OPContextProvider>
    </CCContextProvider>
    </MarketingContextProvider>
    </NsdoContextProvider>
    </DKContextProvider>
    </MechContextProvider>
    </FundContextProvider>
    </NPContextProvider>
    </SMPContextProvider>
    </NuSpbContextProvider>
    </ManagementSpbContextProvider>
    </ManagementSecretContextProvider>
    </DesContextProvider>
    </SupplySpbContextProvider>
    </SupplyContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
