import React, {useState, useEffect, useContext} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Row, Col, Button, Stack, Form, Modal} from "react-bootstrap";
import { UserContext } from '../UserContextManager';
import axios from 'axios';
import cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

function MarketingMainTerminal(){

  const uContext = useContext(UserContext);
  const navigate = useNavigate();

  const [dropdownData, setDropdownData] = useState([]);
  const [formData, updateFormData] = useState({});

  const [paymentType, setPaymentType] = useState('Нал');
  const [item, setItem] = useState('Статья');
  const [metricValue, setMetricValue] = useState('Метрика');
  const [itemType, setItemType] = useState('Расход');

  const [metrics, setMetrics] = useState([]);

  const [sum, setSum] = useState('');

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  const loadTerminalDropdown = async () => {

    try{
      let dropdownsRes = await axios.get('/redesk/terminal/main-dropdowns', {params: {desk_type: 'marketing'}});

      setDropdownData(dropdownsRes.data.dropdowns_data);
      updateFormData({...formData, 'terminal_item_type': 'Расход'})
    } catch (error){
      navigate('/');
    }
  }

  const handleItem = (e) => {

    let selectedItem = e.target.value;

    let itemSpec = dropdownData.filter(({dropdown_name, value, spec}) => (dropdown_name === 'marketing_item' & value === selectedItem))[0].spec;

    let specs = [];

    if (itemSpec !== null){
      specs = itemSpec.split(';');
    }else{
      specs = dropdownData.filter(({dropdown_name, value, spec}) => (dropdown_name === 'marketing_metric')).map(({value, label, dropdown_name}) => (value));
    }

    let formDataCopy = {...formData}
    delete formDataCopy.terminal_work_type
    formDataCopy['terminal_item'] = selectedItem

    updateFormData(formDataCopy);
    setMetrics(specs);
    setMetricValue('Метрика');
    setItem(selectedItem);
  }

  const handleChange = (e, k=null) => {
    if (k !== null){
      updateFormData({...formData, [k]: e});
    }else{
      updateFormData({...formData, [e.target.name]: e.target.value});
    }
  }

  const compareDraft = () => {

    let draftCopy = [...uContext.userData.draft_data]
    
    let has_duplicate = false
    draftCopy.forEach((x) => {
      if( 
        Math.abs(x.sum) === Number(formData.terminal_sum)
        && x.responsible_user === formData.terminal_responsible_user
        && x.item === formData.terminal_item
        && x.payment_type === paymentType
        && x.metric_4 === formData.terminal_metric_4
        ){
        has_duplicate = true
      }
    })

    return has_duplicate
  }

  const handleSubmitWithConfirm = (e) => {
    if (!formData.terminal_sum){
      alert('Введите сумму!')
      return
    }

    if (!formData.terminal_item){
      alert('Укажите статью!')
      return
    }

    if ((!formData.terminal_sub_item | formData.terminal_sub_item === 'Подстатья')  && item != 'Переброска'){
      alert('Укажите подстатью!')
      return
    }

    if ((!formData.terminal_work_type | formData.terminal_work_type === 'Метрика' ) && item != 'Переброска'){
      alert('Укажите метрику!')
      return
    }

    if (!formData.terminal_responsible_user ){
      alert('Укажите Ответственного!')
      return
    }

    if (item === 'Переброска' && paymentType === 'Безнал'){
      alert('Нельзя делать переброску с таким типом платежа!')
      return
    }

    if (compareDraft()){
      setConfirmModalText('Похожая запись уже есть в черновике. Создать еще одну?')
      setShowConfirmModal(true);
      return
    }

    handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    let draftSendData = {...formData}
    draftSendData['cfo'] = 'Маркетинг'
    draftSendData['desk_owner'] = 'Маркетинг'
    draftSendData['terminal_payment_type'] = paymentType
    draftSendData['terminal_item_type'] = itemType

    if (draftSendData['terminal_company_name'] && draftSendData['terminal_company_name'] === 'Канал'){
      delete draftSendData['terminal_company_name']
    }

    if (draftSendData['terminal_metric_4'] && draftSendData['terminal_metric_4'] === 'Проект'){
      delete draftSendData['terminal_metric_4']
    }

    if (draftSendData['terminal_sub_item'] && draftSendData['terminal_sub_item'] === 'Подстатья'){
      delete draftSendData['terminal_sub_item']
    }

    if (draftSendData['terminal_work_type'] && draftSendData['terminal_work_type'] === 'Метрика'){
      delete draftSendData['terminal_work_type']
    }

    let res = await axios.post('/redesk/draft/send', draftSendData, {headers:{'X-CSRF-TOKEN': cookies.get("csrf_access_token")}});

    let contextData = uContext.userData;
    contextData.draft_data = res.data.draft_data;
    uContext.setUserData(contextData);

    /*document.getElementById("terminal_main_form").reset();
    setItemType('Расход');
    updateFormData({});
    setItem('Статья');
    setPaymentType('Нал');
    setMetricValue('Метрика');
    setSum('');*/
  }


  useEffect(() => {
    loadTerminalDropdown();
    return () => {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <Form id='terminal_main_form' className='mt-2'>
      <Row>
        <Col xs={6}>
          <Form.Group className="mb-3" controlId="t_s">
            <Form.Label>Сумма</Form.Label>
            <CurrencyInput
              autoComplete='off'
              id="terminal_sum"
              name="terminal_sum"
              className='form-control'
              style={{maxHeight: '30px'}}
              value={sum}
              placeholder="Сумма"
              decimalsLimit={2}
              allowNegativeValue={false}
              groupSeparator={' '}
              onValueChange={(e) => {setSum(e); handleChange(e, 'terminal_sum')}}
            />
          </Form.Group>
        </Col>
        <Form.Group as={Col} className="mb-3" controlId="t_i_t" >
          <Form.Label>Тип статьи</Form.Label>
          <Form.Select size="sm" name="terminal_item_type" value={itemType} aria-label="terminal_item_type" onChange={(e) => {setItemType(e.target.value);}}>
            {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'terminal_item_type'){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_s_i">
          <Form.Label>Подстатья</Form.Label>
          <Form.Select size="sm" name="terminal_sub_item" defaultValue="Подстатья" aria-label="terminal_sub_item" onChange={handleChange}>
            <option key="Подстатья">Подстатья</option>
            {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'marketing_sub_item'){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_i">
          <Form.Label>Статья</Form.Label>
          <Form.Select size="sm" name="terminal_item" value={item} aria-label="terminal_item" onChange={(e) => {handleItem(e)}}>
            <option key="Статья" disabled>Статья</option>
            {dropdownData.map(({value, label, dropdown_name, spec}, index) => {if(dropdown_name === 'marketing_item'){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_w_t">
          <Form.Label>Метрика</Form.Label>
          <Form.Select size="sm" name="terminal_work_type" value={metricValue} aria-label="terminal_work_type" onChange={(e) => {handleChange(e); setMetricValue(e.target.value);}} disabled={item === 'Статья' ? true : false}>
            <option key="Метрика">Метрика</option>
            {
              metrics.map(function(item){return(<option key={item} value={item}>{item}</option>)})
            }
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_c_n">
          <Form.Label>Канал</Form.Label>
          <Form.Select size="sm" name="terminal_company_name" defaultValue="Канал" aria-label="terminal_company_name" onChange={handleChange}>
            <option key="Канал">Канал</option>
            {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'marketing_channel'){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_m_4">
          <Form.Label>Проект</Form.Label>
          <Form.Select size="sm" name="terminal_metric_4" defaultValue="Проект" aria-label="terminal_metric_4" onChange={handleChange}>
            <option key="Проект">Проект</option>
            {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'marketing_project'){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_r_u">
          <Form.Label>Ответственный</Form.Label>
          <Form.Select size="sm" name="terminal_responsible_user" defaultValue="Ответственный" aria-label="terminal_responsible_user" onChange={handleChange}>
            <option key="Ответственный" disabled>Ответственный</option>
            {dropdownData.map(({value, label, dropdown_name}, index) => {if(dropdown_name === 'marketing_responsible'){return <option key={value} value={value}>{label}</option>}return null})}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="t_p_t">
          <Form.Label>Тип платежа</Form.Label>
          <Form.Select size="sm" name="terminal_payment_type" value={paymentType} aria-label="terminal_payment_type" onChange={(e) => {setPaymentType(e.target.value)}}>
            <option key="Нал">Нал</option>
            <option key="Безнал">Безнал</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="t_c">
          <Form.Label>Примечание</Form.Label>
          <Form.Control size="sm" autoComplete='off' name="terminal_comment" placeholder="Примечание" type="text" onChange={handleChange} />
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Stack gap={2}>
          <Button variant="primary" className="mb-3 mt-2 mx-auto" onClick={handleSubmitWithConfirm}>
            Отправить
          </Button>
        </Stack>
      </Row>
      <Modal centered show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
      <Modal.Body>
        <p>{confirmModalText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick = {() => setShowConfirmModal(false)}>
          Нет
        </Button>
        <Button variant="danger" style={{minWidth: "12%"}} onClick={(e) => {handleSubmit(e); setShowConfirmModal(false)}}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
    </Form>
  )
}


export default MarketingMainTerminal;
